// Functions to handle the global loader "curtain"
window.removeGlobalLoader = () => {
  if (document.querySelector('#global-loader')) {
    setTimeout(
      () =>
        document.querySelector('#global-loader')
          ? document.querySelector('#global-loader').remove()
          : () => null,
      250,
    );
  }
};

window.setGlobalLoaderText = (text) => {
  if (document.querySelector('#global-loader-text')) {
    document.querySelector('#global-loader-text').innerHTML = text;
  }
};

function ieVersion(uaString) {
  uaString = uaString || navigator.userAgent; // eslint-disable-line no-param-reassign
  const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
  if (match) {
    return parseInt(match[2], 10);
  }
  return null;
}
const iev = ieVersion();
// > 11 should be Edge
if (iev && iev < 12) {
  if (document.querySelector('#tempHeader')) {
    document.querySelector('#tempHeader').style.visibility = 'hidden';
  }
  if (document.querySelector('#tempError')) {
    document.querySelector('#oldBrowser').style.visibility = 'visible';
  }
  if (document.querySelector('#global-loader')) {
    document.querySelector('#global-loader').remove();
  }
} else {
  // we're not using IE, so let the user know if its loading slow!
  setTimeout(() => {
    if (document.querySelector('#tempError')) {
      document.querySelector('#tempError').style.visibility = 'visible';
    }
    if (document.querySelector('#global-loader')) {
      document.querySelector('#global-loader').remove();
    }
  }, 3000);
}
